.services-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .services {
    padding: 64px 128px;
    display: flex;
    flex-direction: column;
    gap: 48px;

    .services-header {
      display: flex;
      justify-content: center;
      color: #384158;
    }

    .services-body {
      display: flex;
      justify-content: center;
      color: #76777a;
      font-size: 18px;
      text-align: justify;
      gap: 64px;
      flex-direction: column;

      .services-body-text {
        line-height: 1.5em;
      }

      .services-body-cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 48px;
        align-items: flex-start;

        .services-card {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: flex-start;
          max-width: 272px;
          display: flex;
          gap: 16px;

          .services-image {
            margin: 0 auto;
          }

          .services-card-header {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            margin: 0 auto;
          }

          .services-card-list {
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .services-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .services {
      padding: 64px 16px;
      gap: 24px;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .services-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .services {
      padding: 32px 48px;
      gap: 48px;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .services-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .services {
      padding: 32px 48px;
      gap: 48px;
    }
  }
}
