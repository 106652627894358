.footer-container {
  width: 100%;
  height: 240px;
  background-color: #1e2434;
  display: flex;
  justify-content: center;

  .footer {
    width: 100%;
    max-width: 1200px;
    height: auto;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 32px;
    align-items: center;
    margin: 48px 32px;
    font-size: 18px;
    color: #dadada;
    font-weight: 700;

    .logo {
      width: auto;
      height: auto;
      display: flex;
    }

    .footer-cred {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .footer-name {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
    .footer-contacts {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .footer-contacts-body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .footer-contact {
          display: flex;
          flex-direction: row;
          gap: 8px;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }

    .footer-address {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .footer-address-body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
/* Responsive adjustments */
@media screen and (max-width: 480px) {
  .footer-container {
    width: 100%;
    height: fit-content;
    justify-content: flex-start;

    .footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin: 48px 16px;
      justify-content: center;

      .footer-logo {
        text-align: center;
      }

      .footer-cred {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        justify-content: center;
        .footer-name {
          display: flex;
          flex-direction: column;
          gap: 16px;
          justify-content: center;
          text-align: center;
        }
      }
      .footer-contacts {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        text-align: center;
        .footer-contacts-body {
          display: flex;
          flex-direction: column;
          gap: 16px;
          text-align: center;
          justify-content: center;
          .mail-contact {
            text-align: center;
            justify-content: center;
          }
          .phone-contact {
            text-align: center;
            justify-content: center;
          }
        }
      }

      .footer-address {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        text-align: center;
        justify-content: center;
        .footer-address-body {
          display: flex;
          flex-direction: column;
          gap: 16px;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .footer-container {
    width: 100%;
    height: fit-content;
    justify-content: flex-start;

    .footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin: 48px 16px;
      justify-content: center;

      .footer-logo {
        text-align: center;
      }

      .footer-cred {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        justify-content: center;
        .footer-name {
          display: flex;
          flex-direction: column;
          gap: 16px;
          justify-content: center;
          text-align: center;
        }
      }
      .footer-contacts {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        text-align: center;
        .footer-contacts-body {
          display: flex;
          flex-direction: column;
          gap: 16px;
          text-align: center;
          justify-content: center;
          .mail-contact {
            text-align: center;
            justify-content: center;
          }
          .phone-contact {
            text-align: center;
            justify-content: center;
          }
        }
      }

      .footer-address {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        text-align: center;
        justify-content: center;
        .footer-address-body {
          display: flex;
          flex-direction: column;
          gap: 16px;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .footer {
    justify-content: space-between;
  }
}
