.menu-link-mobile {
  display: flex;
  align-items: center;
  height: 32px;
  color: #384158;
  text-decoration: none;
  transition: all 200ms ease;
  font-size: large;
  font-weight: 700;
  margin: 8px 16px;
}
