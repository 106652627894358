.contacts-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .contacts {
    padding: 64px 128px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;

    .contacts-block {
      display: flex;
      flex-direction: column;
      gap: 32px;
      min-width: fit-content;

      .contacts-header {
        display: flex;
        justify-content: center;
        color: #384158;
      }

      .contacts-body {
        display: flex;
        justify-content: center;
        color: #76777a;
        font-size: 18px;
        text-align: justify;
        gap: 24px;
        flex-direction: column;

        .contacts-body-block {
          display: flex;
          justify-content: center;
          color: #76777a;
          font-size: 18px;
          text-align: justify;
          gap: 16px;
          flex-direction: column;

          .contacts-body-header {
            font-size: 18px;
            font-weight: 700;
            line-height: 1.5em;
          }

          .contacts-body-text {
            display: flex;
            flex-direction: column;
            line-height: 1.5em;
            gap: 12px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .contacts-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .contacts {
      padding: 64px 16px;
      gap: 24px;
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .contacts-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .contacts {
      padding: 32px 48px;
      gap: 48px;
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .contacts-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .contacts {
      padding: 32px 48px;
      gap: 48px;
    }
  }
}
